import React, { useState, useEffect } from "react";
import "./ActionPanel.css";

function AssigneeComponent({ itemId, assignee, handleSaveAssignee }) {
  const [users, setUsers] = useState([]);
  const [currentAssignee, setCurrentAssignee] = useState(assignee);
  const currentUsername = localStorage.getItem("username");

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("userToken");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      try {
        const response = await fetch("/api/users", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log("Users:", data);
        setUsers(data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Update local state when assignee changes
  useEffect(() => {
    setCurrentAssignee(assignee);
  }, [assignee]);

  // Function to send email notifications when task is assigned
  const sendEmailNotification = async (newAssigneeUsername) => {
    try {
      const taskLink = `${window.location.origin}/task/${itemId}`;
      const emailPayload = {
        to: `${newAssigneeUsername}@transcent.in`, // Adjust email as needed
        subject: "You have been assigned a new task",
        body: `Task ID: <a href="${taskLink}">${itemId}</a> is assigned to you. Please check your tasks for more details.`,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sendEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailPayload),
        }
      );

      if (!response.ok) {
        console.error("Failed to send email notification");
      } else {
        console.log("Email notification sent successfully");
      }
    } catch (error) {
      console.error("Error sending email notification:", error);
    }
  };

  // Function to update the task assignee
  const updateAssignee = async (newAssigneeUsername) => {
    if (currentAssignee !== newAssigneeUsername) {
      try {
        const taskLink = `${window.location.origin}/task/${itemId}`;
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/items/${itemId}/assignee`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              newAssignee: newAssigneeUsername,
              comment: `<a href="${taskLink}">Task ID: ${itemId}</a> is assigned to ${newAssigneeUsername}.`,
              user: localStorage.getItem("username"),
            }),
          }
        );

        if (!response.ok) {
          console.error("Failed to update assignee");
        } else {
          const updatedItem = await response.json();
          handleSaveAssignee(updatedItem);
          await sendEmailNotification(newAssigneeUsername); // Send email after assignment
          setCurrentAssignee(newAssigneeUsername);
          console.log("Assignee updated, comment posted, and email sent");
        }
      } catch (error) {
        console.error("Error during the updateAssignee process:", error);
      }
    }
  };

  return (
    <div>
      <label>Assignee:</label>
      <select
        value={currentAssignee}
        onChange={(e) => updateAssignee(e.target.value)}
        className="priority-box"
      >
        <option value="unassigned">unassigned</option>
        {users.map((user) => (
          <option key={user.username} value={user.username}>
            {user.username}
          </option>
        ))}
      </select>
    </div>
  );
}

export default AssigneeComponent;
