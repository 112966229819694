import React, { useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams(); // Get the token from the URL
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // Add a loading state

  useEffect(() => {
    console.log("Token from URL:", token); // Log the token to ensure it's being passed
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true); // Start loading

    try {
      await axios.post(`/api/resetpassword/${token}`, {
        password,
      });
      setSuccess("Password has been reset successfully.");
      setLoading(false); // Stop loading

      // After 3 seconds, navigate to the login page
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error(
        "Error resetting password:",
        error.response || error.message
      );
      setError("Failed to reset password. Please try again.");
      setLoading(false); // Stop loading on error
    }
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const cardStyle = {
    width: "400px",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const inputStyle = {
    height: "45px",
    fontSize: "1em",
  };

  const buttonStyle = {
    width: "100%",
    backgroundColor: "#007bff",
    borderColor: "#007bff",
  };

  return (
    <div style={wrapperStyle}>
      <Card style={cardStyle}>
        <Card.Body>
          <h3 className="text-center mb-4">Reset Password</h3>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          {loading && <p>Loading...</p>}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your new password"
                onChange={(e) => setPassword(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Button
              type="submit"
              className="mt-4"
              style={buttonStyle}
              disabled={loading}
            >
              Reset Password
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResetPassword;
